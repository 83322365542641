import { ContextPlugin, TenantConfig } from '../components/AttributeBuilder/contextPlugins';
import { Datasource } from '../model/datasources/datasources';

export const definedConfigTableHeaders:any[] = [{
  name: 'key',
  value: 1,
  key: 'key',
  isVisible: false,
  isSortable: false,
}, {
  name: 'Output field',
  value: 1,
  key: 'outputField',
  isVisible: true,
  isSortable: true,
}, {
  name: 'Data type',
  value: 1,
  key: 'dataType',
  isVisible: true,
  isSortable: true,
}, {
  name: 'Context Attribute',
  value: 1,
  key: 'ctxAttributeCard',
  isVisible: true,
  isSortable: false,
}];

export const tenantConfig: TenantConfig = { id: '', tenantId: '', contextPluginId: '', isEnabled: false, refreshRate: 0 };
export const emptyPluginState: ContextPlugin[] = [
  {
    id: '',
    uid: '',
    tenantId: '00000000-0000-0000-0000-000000000000',
    isReserved: false,
    isPreview: false,
    refreshRate: 0,
    supportedPlatforms: [''],
    category: '',
    userScope: false,
    isSaRestricted: false,
    name: '',
    description: '',
    iconUrl: '',
    values: {},
    tenantConfig: tenantConfig,
    isView: false,
    isCreate: false,
    isCreateAttribute: false,
    isChanged: false,
    provider: '',
    dataSource: '',
    tenantAccess: Object,
  },
];

export const datasouceInit:Datasource = {
  id: '',
  connectorId: '',
  name: '',
  providerName: '',
  description: '',
  imageUrl: '',
  updatedAt: 0,
  tenantViewScope: [],
  status: '',
  documentationUrl: '',
  supportEmail: '',
  pluginId:'',
  auth: {
    status: '',
  },
  configs: {
    identityTag: '',
    definedConfigs: {},
    dynamicConfigs: {},
  },
  draftPlugin: emptyPluginState[0],
};

export const errorTrackerDefaultValue = {
    basicInfo: {
      name: 0,
      providerName: 0,
      count: 0,
    },
    configurations: {
      identityTag: 0,
      category: 0,
      count: 0,
      definedConfigs: 0,
      dynamicConfigs: 0,
    }
};

export const dynaicConfigDefaultValue = {
  heading: {
    name: '',
    viewName: '',
    description: '',
    isOutput: false,
  },
  fieldColumns: [],
};

export const ConfigValuesDefaultValue = {
  identityTagValue: '',
  definedConfigValues: {},
  dynamicConfigValues: {
    heading: {
      name: '',
      viewName: '',
      description: '',
      isOutput: false,
    },
    fieldColumns: [{
        name: '',
        viewName: '',
        description: '',
        inputType: '',
        inputOpts: [],
      },
    ],
  }
};

export const basicInfoDefaultValues = {
  connectorInstanceName: '',
  providerName: '',
  connectorId: '',
  description: '',
  documentationUrl: '',
  imageUrl: '',
  supportEmail: '',
};

export const formDataDefaultValues = {
  identityTag: '',
  dynamicConfigFormData: [
    {
      tableName: '',
      columnName: '',
      dataType: '',
      outputFieldName: '',
    },
  ],
  category: '',
  uid: '',
};

export const authConfigsDefaultValues = {
  clientId: '',
  clientSecret: '',
  authStatus: '',
};

export const configTemplateDefaultValues = {
  identityTag: '',
  definedConfigs: [],
  dynamicConfigs: [],
};