import React, { useState } from 'react';
import {
  FlightTextInput,
  FlightButton,
  FlightSelect,
  FlightTable,
  FlightNumberInput,
} from '@flybits/webapp-design-system-react';
import { getTimeMinutes, minuteTimeConverter, getTimeUnitMinutes } from './timeConverters';
import { Formik, Field } from 'formik';
import { Project } from './project';
import { ReactComponent as TrashBin } from './trash_bin.svg';
import { Attributes, ContextPlugin } from './contextPlugins';
import { NO_SPECIAL_CHARACTERS } from './errors';
import DoubleArrayFields from './DoubleArrayFields/DoubleArrayFields';
import './CreateContextAttribute.scss';
import { Sidepanel } from './Sidepanel/Sidepanel';
import { ValueTypeOptions, Parameters, Parameter, ParametersForApi, TableBody, SelectOptionProps } from './model';
import { dataFormatOptions } from './constants/dataOptions';
import { scopedOptions } from './constants/scopedOptions';

import {
  validateFloat,
  validateInteger,
  validateBoolean,
  validateString,
  validationSchema,
} from './validationAndRegex';
import { inputTypeOptions } from './constants/inputTypeOptions';

interface Props {
  callback: (data?: Attributes, name?: string) => void; //called when attribute form is submitted, make sure to put a toggle to close the panel in this function
  connectorInstanceId: string;
  isReserved?: boolean;
  tenantData?: Project[];
  contextPluginData?: ContextPlugin[];
  isVisible: boolean;
  toggleSidePanel: () => void;
}

export default function CreateContextAttribute(props: Props) {
  const { callback, contextPluginData = [], isReserved, connectorInstanceId } = props;
  const arrValues: Array<string> = [];
  const nameValues: Array<string> = [];
  let valueTypeOptions: ValueTypeOptions[] = [];
  const [valueTypes, setValueTypes] = useState([{ value: '', displayName: '' }]);
  const [valuesFromArrayFields, setValuesFromArrayFields] = useState<Array<string>>([]);
  const [displayNamesFromArrayFields, setDisplayNamesFromArrayFields] = useState<Array<string>>([]);
  const timeOptions = [
    {
      key: 'neverexpires',
      name: 'Never Expires',
    },
    {
      key: 'minutes',
      name: 'Minutes',
    },
    {
      key: 'hours',
      name: 'Hours',
    },
    {
      key: 'days',
      name: 'Days',
    },
    {
      key: 'weeks',
      name: 'Weeks',
    },
    {
      key: 'months',
      name: 'Months',
    },
    {
      key: 'years',
      name: 'Years',
    },
  ];

  let isViewAttribute = '';
  // let subDomain = undefined;
  // eslint-disable-next-line
  let uid = props.contextPluginData?.[0].uid;
  // if (tenantData.length !== 0) {
  //   [{ subDomain }] = tenantData;
  // }
  let values: Attributes = {};
  if (contextPluginData?.length !== 0) {
    [{ values }] = contextPluginData;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  isViewAttribute = (contextPluginData[0] && contextPluginData[0])?.isViewAttribute!;
  if (isViewAttribute) {
    uid = isViewAttribute;
    isViewAttribute = isViewAttribute.substring(isViewAttribute.lastIndexOf('.') + 1, isViewAttribute.length);
  }
  // const projectName = subDomain?.slice(0, subDomain.length - 12);

  
  let defaultExpDuration = undefined;

  const getValuesFromArrayFields = (data: string, nameData: string) => {
    //Get options and display names
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
    setDisplayNamesFromArrayFields([...displayNamesFromArrayFields.splice(0)]);
    setDisplayNamesFromArrayFields([nameData]);
    valueTypeOptions = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        valueTypeOptions.push({
          value: data[i],
          displayName: nameData[i],
        });
      }
      setValueTypes(valueTypeOptions);
    }
    if (data && isViewAttribute) {
      values[isViewAttribute].valueTypeOptions = valueTypes;
      contextPluginData[0].values[isViewAttribute].valueTypeOptions = valueTypes;
    }
  };

  //Get tenant and context plugin data
  //Checks if attribute already exists
  if (isViewAttribute) {
    valueTypeOptions = values[isViewAttribute].valueTypeOptions;
    defaultExpDuration = values[isViewAttribute].defaultExpDuration;
    if (defaultExpDuration < 0) {
      defaultExpDuration = 1;
    }
    if (Object.keys(valueTypeOptions).length !== 0) {
      for (const value in valueTypeOptions) {
        arrValues.push(valueTypeOptions[value].value);
        nameValues.push(valueTypeOptions[value].displayName);
      }
      if (arrValues.length < 1) {
        arrValues.push('');
        nameValues.push('');
      }
    } else {
      arrValues.push('');
      nameValues.push('');
    }
  } else {
    arrValues.push('');
    nameValues.push('');
  }
  const initialFormValues = {
    name: values[isViewAttribute]?.name ? values[isViewAttribute].name : '',
    description: values[isViewAttribute]?.description ? values[isViewAttribute].description : '',
    valueType: values[isViewAttribute]?.valueType ? values[isViewAttribute].valueType : '',
    enableOptions:
      values[isViewAttribute]?.valueTypeOptions && values[isViewAttribute].valueTypeOptions.length !== 0 ? true : false,
    enableValues: false,
    tenantScoped: false,
    historicalPersistence: values[isViewAttribute]?.shouldBeSaved ? values[isViewAttribute].shouldBeSaved : true,
    dateExpiresAt:
      values[isViewAttribute]?.defaultExpDuration && values[isViewAttribute]?.defaultExpDuration > 0
        ? getTimeMinutes(values[isViewAttribute].defaultExpDuration)
        : 1,
  };
  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };

  const [parameterDelete, setParameterForDelete] = useState<Parameter>({ key: '', valueTypeOptions: [] });

  //Set proper validation for parameters
  const setValidationSchema = (valueType: string) => {
    if (valueType === 'float') {
      return validateFloat;
    } else if (valueType === 'int') {
      return validateInteger;
    } else if (valueType === 'boolean') {
      return validateBoolean;
    } else {
      return validateString;
    }
  };

  const [dataFormat, setDataFormat] = useState<SelectOptionProps>({
    key: 'string',
    name: 'String',
  });

  const [scope, setScope] = useState<SelectOptionProps>({
    key: 'user',
    name: 'User',
  });

  const [inputType, setInputType] = useState<SelectOptionProps>({
    key: 'freeform',
    name: 'Freeform',
  });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal((showCreateModal) => !showCreateModal);
  };
  const [newParameters] = useState<Parameters[]>([]);
  const toggleDeleteConfirm = (parameter: Parameter) => {
    if (parameterDelete.key === '') {
      setParameterForDelete(parameter);
    } else {
      setParameterForDelete({ key: '', valueTypeOptions: [] });
    }
  };
  const [expiryDateTime, setExpiryDateTime] = useState<SelectOptionProps>({
    key: 'neverexpires',
    name: 'Never Expires',
  });

  // eslint-disable-next-line
  const [editAttributeId, setEditAttributeId] = useState(false);
  // eslint-disable-next-line
  function toggleEditAttributeId() {
    setEditAttributeId((editAttributeId) => !editAttributeId);

    //sets the correct data expiry if attribute already exists
    if (isViewAttribute && values[isViewAttribute].defaultExpDuration) {
      if (values[isViewAttribute].defaultExpDuration > 1 && expiryDateTime.key === 'neverexpires') {
        setExpiryDateTime(minuteTimeConverter(values[isViewAttribute].defaultExpDuration));
      }
    }
  }
  // eslint-disable-next-line
  const handleTimeOptionClick = (option: SelectOptionProps) => {
    setExpiryDateTime(option);
  };
  const tableHeaders = [
    {
      key: 'keyName',
      name: 'Key',
      isVisible: true,
      hideTooltip: true,
    },
    {
      key: 'inputType',
      name: 'Input Type',
      isVisible: true,
      hideTooltip: true,
    },
    {
      key: 'trash',
      name: '',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  //Creates the parameter table
  const tableBody: TableBody[] = [];
  for (const parameter in newParameters) {
    const iterator = newParameters[parameter];
    for (const innerKey in iterator) {
      tableBody.push({
        keyName: (
          <span
            style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              toggleCreateModal();
            }}
          >
            {iterator[innerKey].key}
          </span>
        ),
        inputType:
          iterator[innerKey].valueTypeOptions?.length !== 0 &&
          iterator[innerKey].valueTypeOptions?.[0]?.value?.length !== 0
            ? 'dropdown'
            : 'freeform',
        key: Math.random(),
        trash: <TrashBin className="trash" onClick={() => toggleDeleteConfirm(iterator[innerKey])} />,
      });
    }
  }

  return (
    <>
      <Sidepanel isVisible={props.isVisible} toggleClose={props.toggleSidePanel}>
        <div className="create-context-attribute">
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const parametersForApi: ParametersForApi[] = [];
              let index = 0;
              //Puts parameters in the right format for the callback function
              for (const parameter in newParameters) {
                const iterator = newParameters[parameter];
                for (const innerKey in iterator) {
                  parametersForApi.push({
                    index: index,
                    key: iterator[innerKey].key,
                    isShowValue: values.enableValues,
                    isShowDisplayName: true,
                    valueTypeOptions: iterator[innerKey].valueTypeOptions,
                    valueType: !values.valueType
                      ? 'string'
                      : values.valueType === 'DateTime'
                      ? 'int'
                      : values.valueType,
                    valueKeys: null,
                    tenantId: '00000000-0000-0000-0000-000000000000',
                    performsExtraProcessing: false,
                    extraProcessingAddress: '',
                    isTenantScoped: values.tenantScoped,
                    shouldBeSaved: values.historicalPersistence,
                    isTimeContext: false,
                  });
                  index++;
                }
              }
              const timeUnit = getTimeUnitMinutes(expiryDateTime.key);
              if (values.valueType === 'DateTime') {
                values.valueType = 'int';
              }
              const createContextAttribute = {
                uid: `${(contextPluginData && contextPluginData[0])?.uid}.${values.name}`,
                name: values.name,
                description: values.description,
                valueType: dataFormat.key,
                valueKeys: [],
                inputAttrExpiryRate: values.dateExpiresAt,
                inputAttrExpiryRateUnit: expiryDateTime.key !== 'neverexpires' ? expiryDateTime.key : '',
                defaultExpDuration: expiryDateTime.key === 'neverexpires' ? -1 : values.dateExpiresAt * timeUnit,
                isNeverExpires: expiryDateTime.key === 'neverexpires',
                valueTypeOptions: valueTypes,
                parameters: parametersForApi,
                isTenantScoped: scope.key === 'project' ? true : false,
                isTimeContext: false,
                shouldBeSaved: values.historicalPersistence,
                isShowValue: values.enableValues,
                isShowDisplayName: true,
                refreshDestination: {
                  connectorInstanceId,
                },
              };
              callback({ [values.name]: createContextAttribute }, values.name);
              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue }) => (
              <div className="create-attribute">
                <div className="create-attribute__header">
                  <h4>Create Attribute</h4>
                </div>
                <div className="create-attribute__content">
                  <div className="create-attribute__content__form">
                    <div className="create-attribute__content__form__group">
                      <div className="create-attribute__content__form__group__label">
                        Attribute Name
                        <div>
                          <Field
                            type="text"
                            name="name"
                            className="create-attribute__content__form__group__field"
                            label={'Attribute Name'}
                            as={FlightTextInput}
                            width="335px"
                            hasError={
                              (touched.name && errors.name) ||
                              Object.keys((contextPluginData && contextPluginData[0])?.values)?.includes(values.name)
                                ? true
                                : false
                            }
                            value={values.name}
                            errorMessage={
                              <span>
                                {Object.keys((contextPluginData && contextPluginData[0])?.values)?.includes(values.name)
                                  ? 'Attribute with that label already exists '
                                  : errors.name}
                              </span>
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="create-attribute__content__form__group__label">
                        Description
                        <Field
                          type="text"
                          name="description"
                          className="create-attribute__content__form__group__field"
                          as={FlightTextInput}
                          label={'Description'}
                          width="335px"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="create-attribute__content__form__group">
                      <div className="create-attribute__content__form__group__label">
                        Attribute ID
                        <div className="create-attribute__content__form__group__edit">
                          <span className="create-attribute__content__form__group__edit__text">{`${contextPluginData[0]?.uid}.${values.name}`}</span>
                        </div>
                      </div>
                      <div className="create-attribute__content__form__group__label">
                        Data Expiry
                        <div className="create-attribute__expiry">
                          {expiryDateTime.key !== 'neverexpires' ? (
                            <Field
                              type="number"
                              name="dateExpiresAt"
                              className="create-attribute__expiry__input"
                              as={FlightNumberInput}
                              disabled={isReserved}
                              placeholderText="Expires after"
                              width="100px"
                              maxValue={1000}
                              minValue={0}
                              value={values.dateExpiresAt}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          ) : null}
                          <FlightSelect
                            label=""
                            className="create-attribute__expiry__dropdown"
                            width="100px"
                            disabled={isReserved}
                            selected={expiryDateTime}
                            options={timeOptions}
                            handleOptionClick={handleTimeOptionClick}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="create-attribute__content__form__group__label">
                      Assigned Category
                      <div className="create-attribute__content__form__group">
                        <div>
                          <Field
                            type="text"
                            name="category"
                            as={FlightTextInput}
                            width="335px"
                            disabled={true}
                            value={contextPluginData[0]?.category}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="create-attribute__content__form__group">
                      <div className="create-attribute__content__form__group__label">
                        Data Format
                        <div>
                          <FlightSelect
                            label="Data Format"
                            className="create-attribute__content__form__group__field"
                            width="332px"
                            disabled={isReserved}
                            selected={dataFormat}
                            options={dataFormatOptions}
                            handleOptionClick={(value) => setDataFormat(value)}
                          />
                        </div>
                      </div>
                      <div className="create-attribute__content__form__group__label">
                        Scope
                        <div>
                          <FlightSelect
                            label="Scope"
                            className="create-attribute__content__form__group__field"
                            width="332px"
                            disabled={true}
                            selected={scope}
                            options={scopedOptions}
                            handleOptionClick={(value) => setScope(value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="create-attribute__content__form__group__label">
                      Input Type
                      <div className="create-attribute__content__form__group">
                        <FlightSelect
                          label="Input Type"
                          className="create-attribute__content__form__group__field"
                          width="332px"
                          disabled={isReserved}
                          selected={inputType}
                          options={inputTypeOptions}
                          handleOptionClick={(value) => setInputType(value)}
                        />
                      </div>
                    </div>
                    {inputType.key === 'dropdown' && values.valueType !== 'dateTime' ? (
                      <div className="create-attribute__content__form__array_double">
                        <DoubleArrayFields
                          callback={getValuesFromArrayFields}
                          removeListener={showCreateModal}
                          initialValues={{ values: arrValues, names: nameValues }}
                          hasError={getErrorState}
                          placeHolderText="Enter value"
                          secondPlaceHolderText="Enter display name"
                          validationSchema={setValidationSchema(values.valueType)}
                        />
                      </div>
                    ) : null}

                    {tableBody.length !== 0 ? (
                      <FlightTable
                        className="create-attribute__table"
                        tableHeaders={tableHeaders}
                        tableData={tableBody}
                        hasPaginationBeforeTable={false}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="create-attribute__footer">
                  <div className="create-attribute__footer__buttons">
                    <FlightButton
                      onClick={handleSubmit}
                      disabled={
                        errors.name === NO_SPECIAL_CHARACTERS ||
                        isReserved ||
                        (hasError && values.valueType !== 'DateTime' && values.enableOptions) ||
                        values.name.length < 1 ||
                        (!isViewAttribute &&
                          Object.keys((contextPluginData && contextPluginData[0])?.values)?.includes(values.name))
                      }
                      className="create-attribute__footer__buttons-create"
                      label="Create"
                      theme="primary"
                    />
                    <FlightButton
                      onClick={props.toggleSidePanel}
                      className="create-attribute__footer__buttons-c"
                      label="Cancel"
                      theme="secondary"
                    />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Sidepanel>
    </>
  );
}
