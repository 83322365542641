import React, { FunctionComponent, useState } from 'react';
import {
  FlightButton,
  FlightModal,
  FlightCard,
  FlightSnackbar,
  FlightTextInput,
} from '@flybits/webapp-design-system-react';
import { useHistory } from 'react-router-dom';
import { SubmitProps } from '../../model/datasources/datasources';
import { Formik, Form as FormikForm } from 'formik';
import './ShareSettings.scss';
import { Datasource } from '../../model/datasources/datasources';
import DatasourceService from '../../services/datasources.service';
import { ShareDataSourceProject } from '../../model/projects/projects';

interface Props {
  openModal: boolean;
  datasource?: Datasource;
  selectedProjects: ShareDataSourceProject[];
  toggleModal: () => void;
  setSelectedProjects: (arg: ShareDataSourceProject[]) => void;
}

const initialFormValues = {};

const MAIN_CLASS = 'ShareSettings__modal';
const CONTENT_CLASS = `${MAIN_CLASS}__content`;
const PROJECTS_LIST_CLASS = `${CONTENT_CLASS}__projects-list`;
const HEADER_CLASS = `${MAIN_CLASS}__header`;
const FOOTER_CLASS = `${MAIN_CLASS}__footer`;
const FOOTER_CANCEL_CLASS = `${MAIN_CLASS}__footer__cancel-btn`;

const ShareSettings: FunctionComponent<Props> = ({
  openModal,
  toggleModal,
  selectedProjects,
  datasource,
  setSelectedProjects,
}) => {
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [searchText, setSearchText] = useState('');
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const [isTextValid, setIsTextValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const handleRemoveProject = (key: string) => {
    const filteredProjects = selectedProjects.filter((item) => item.key !== key);

    setSelectedProjects(filteredProjects);
  };

  const handleShare = async () => {
    if (selectedProjects.length) {
      selectedProjects.forEach((project) => {
      if (datasource) {
        datasource.tenantViewScope.push(project.key);
      }
      });

      if (datasource) {
        setIsSharing(true);
        datasource.status = 'enabled';
        datasource.pluginId = datasource.draftPlugin.uid;

        const datasourceServiceManager = new DatasourceService();

        try {
          await datasourceServiceManager.updateDatasource(datasource);
          setIsSharing(false);
          toggleModal();
          history.push(`/datasources`);
        } catch(e) {
          setIsSharing(false);
          setErrorMessage(e?.message || 'There has been an error');
        }
      }
    }
  };

  const validateProjectId = (text: string) => {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(text);
  }

  const handleSearch = (e: any) => {
    if (validateProjectId(e.target.value)) {
      setSearchText(e.target.value);
      setIsTextValid(true);
    } else {
      setSearchText(e.target.value);
      setIsTextValid(false);
    }
  };

  const handleAddProjectId = (e: any) => {
    e.preventDefault();

    if (searchText.length > 0) {
      const option = {
        key: searchText,
      };

    if (e.key === 'Enter') {
      if (isTextValid) {
        setIsTextValid(true);
        setSelectedProjects([...selectedProjects, option]);
        setSearchText('');
      } else {
        setIsTextValid(false);
        return;
      }
    } else {
      if (isTextValid) {
        setIsTextValid(true);
        setSelectedProjects([...selectedProjects, option]);
        setSearchText('');
      } else {
        setIsTextValid(false);
        return;
      }
    }
    }
  };

  const onSubmit = (values: any, { resetForm }: SubmitProps) => {
    handleShare();
    resetForm();
  };

  return (
    <div className={MAIN_CLASS}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, handleReset }) => {
          return (
            <FormikForm>
              <FlightModal
                isVisible={openModal}
                toggleModalShown={(e: React.FormEvent<HTMLInputElement>) => {
                  handleReset(e);
                  toggleModal();
                }}
                size="medium"
                scrollable={true}
                header={<span className={HEADER_CLASS}>Share Settings</span>}
                content={
                  <div className={CONTENT_CLASS}>
                    <div className={`${CONTENT_CLASS}__info-container`}>
                      <div className={`${CONTENT_CLASS}__info-container-grid`}>
                          <FlightSnackbar
                            isVisible={errorMessage.length > 0}
                            icon="error"
                            content={errorMessage}
                            type="error"
                            isAutoDismiss={true}
                            actionName=""
                            isFloating={false}
                          />

                          <FlightSnackbar
                            isVisible={errorMessage.length < 1}
                            isFloating={false}
                            className={`${CONTENT_CLASS}__info`}
                            icon="info"
                            isAutoDismiss={true}
                            actionName=""
                            content="Once your datasource is shared with a project, the name,
                              provider and context data output configurations can no longer
                              be changed."
                            type="warning"
                          />

                        <p className={`${CONTENT_CLASS}__desc`}>
                          For projects you are not an admin of you will only be able to add them if you know their
                          project ID.
                        </p>
                      </div>
                    </div>

                    <div className={`${CONTENT_CLASS}__selections`}>
                      <FlightTextInput
                        label="Select your project or enter a project ID"
                        className={`${CONTENT_CLASS}__search`}
                        name="search"
                        value={searchText}
                        hasError={searchText.length > 0 && !isTextValid}
                        errorMessage={<span>Enter a valid project ID</span>}
                        onChange={(e: any) => {
                          handleSearch(e);
                        }}
                        onKeyPress={handleAddProjectId}
                        width="92%"
                      />
                      <FlightButton
                        type="button"
                        label="Add"
                        disabled={!searchText.length || !isTextValid}
                        size="large"
                        theme="link"
                        onClick={handleAddProjectId}
                      />
                    </div>
                    <div className={PROJECTS_LIST_CLASS}>
                      {selectedProjects.map((project: ShareDataSourceProject) => {
                        return (
                          <FlightCard
                            key={project.key}
                            header={<p className={`${PROJECTS_LIST_CLASS}__item-name`}>{project.key}</p>}
                            className={`${PROJECTS_LIST_CLASS}__item`}
                            footer=""
                            actionables={
                              <FlightButton
                                label=""
                                size="large"
                                iconLeft="clear"
                                theme="link"
                                onClick={() => {
                                  handleRemoveProject(project.key);
                                }}
                              />
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                }
                footer={
                  <div className={FOOTER_CLASS}>
                    <FlightButton
                      onClick={(e: React.FormEvent<HTMLInputElement>) => {
                        handleReset(e);
                        setInitialValues(initialFormValues);
                        toggleModal();
                      }}
                      className={FOOTER_CANCEL_CLASS}
                      label="Cancel"
                      theme="secondary"
                    />
                    <FlightButton
                      type="submit"
                      label={
                        !selectedProjects.length
                          ? 'Share with project'
                          : `Share with ${selectedProjects.length} projects`
                      }
                      loading={isSharing}
                      onClick={() => undefined}
                      disabled={isSharing || !(isTextValid || selectedProjects.length > 0)}
                    />
                  </div>
                }
              />
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default ShareSettings;
