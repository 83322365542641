import React, { FunctionComponent, useState, useEffect, MouseEvent } from 'react';
import { ContextPlugin } from '../../components/AttributeBuilder/contextPlugins';
import Authorization from '../../components/Authorization/Authorization';
import Configurations from '../../components/Configurations/Configurations';
import DatasourceHeader from '../../components/DatasourceHeader/DatasourceHeader';
import DatasourceInfo from '../../components/DatasourceInfo/DatasourceInfo';
import DatasourceSidebar from '../../components/DatasourceSidebar/DatasourceSidebar';
import ShareSettings from '../../components/ShareSettings/ShareSettings';
import { FlightModal, FlightSnackbar } from '@flybits/webapp-design-system-react';
import ConnectorService from '../../services/connectors.service';

// import SvgConfigLockIcon from '../../components/ConfigLockIcon/ConfigLockIcon';
import { useParams } from 'react-router-dom';
import DatasourceService from '../../services/datasources.service';
import ProjectService from '../../services/project.service';
import { FlightButton } from '@flybits/webapp-design-system-react';
import { serializePluginAttributes } from '../../helpers/datasources';
import { emptyPluginState, datasouceInit, errorTrackerDefaultValue, dynaicConfigDefaultValue,
  ConfigValuesDefaultValue, basicInfoDefaultValues,formDataDefaultValues,
  authConfigsDefaultValues, configTemplateDefaultValues } from '../../constants/datasource-config';
import { transformDefinedConfigFields, transformDynamicConfigFields } from '../../helpers/configurations';
import { Datasource, DynamicConfig,
  DynamicConfigTableHeaders, DefinedConfig, ConfigTemplate, ConfigValues, DynamicConfigTableData, FormData,
  SelectedConfigData,
} from '../../model/datasources/datasources';
import { ShareDataSourceProject , ProjectResponseBody} from '../../model/projects/projects';
import '../../styles/pages/Datasources/DatasourcesShare.scss';

const DatasourcesShare: FunctionComponent<any> = (props) => {
  // eslint-disable-next-line
  const clonedEmptyPluginState = JSON.parse(JSON.stringify(emptyPluginState));
  // eslint-disable-next-line
  const [activeClass, setActive] = useState<string>('datasource_info');
  const [connectorInstanceNameExists, setConnectorInstanceNameExists] = useState<boolean>(false);
  const [selectedConfigData, setSelectedConfigData] = useState<SelectedConfigData>();
  const [currentTab, setTab] = useState<string>('datasource_info');
  const [definedConfigSchema, setDefinedConfigSchema] = useState<DefinedConfig[]>([]);
  const [definedOutputsTableSchema, setDefinedOutputTableSchema] = useState<any>(() => []);
  const [datasourceStatus, setDatasourceStatus] = useState<string>('');
  const [trackedConfigs, setTrackedConfigs] = useState<any>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorsTracker, setErrorsTracker] = useState(errorTrackerDefaultValue);    
  const [datasource, setDatasource] = useState<Datasource>(JSON.parse(JSON.stringify(datasouceInit)));
  const [selectedConfigType, setSelectedConfigType] = useState<string>('');
  const [openSidepanel, setOpenSidePanel] = useState<boolean>(false);
  const [dynamicConfigTableData, setDynamicConfigTableData] = useState<DynamicConfigTableData[]>([]);
  const [dynamicConfigTableHeaders, setDynamicConfigTableHeaders] = useState<DynamicConfigTableHeaders[]>([]);
  const [isUserInput, setUserInput] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ctxIdx, setIdxOfCtxAttr] = useState<string>('');
  const [dynamicViewName, setDynamicViewName] = useState<string>('');  
  const [plugin, setPlugin] = useState<ContextPlugin[]>(clonedEmptyPluginState);
  const [isShowErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isErrorModalActivated, setIsErrorModalActivated] = useState<boolean>(false);    
  const [selectedProjects, setSelectedProjects] = useState<ShareDataSourceProject[]>([]);
  const [numOfSelectedProjects, setNumOfSelectedProjects] = useState<number>(0);
  const [dynamicConfigSchema, setDynamicConfigSchema] = useState<DynamicConfig>(dynaicConfigDefaultValue);
  const [configValues, setConfigValues] = useState<ConfigValues>(ConfigValuesDefaultValue);
  const [configTemplate, setConfigTemplate] = useState<ConfigTemplate>(configTemplateDefaultValues);
  const [basicInfo, setBasicInfo] = useState(basicInfoDefaultValues);
  const [formData, setFormData] = useState<FormData>(formDataDefaultValues);
  // eslint-disable-next-line
  const [limit, setLimit] = useState<number>(1000000);
  // eslint-disable-next-line
  const [projects, setProjects] = useState<ShareDataSourceProject[]>([]);
  // eslint-disable-next-line
  const [authConfigs, setAuth] = useState(authConfigsDefaultValues);

  type SaveStatus = {
    status: string;
    content: string;
  };

  const [saveStatus, setSaveStatus] = useState<SaveStatus>({
    status: '',
    content: '',
  });

  const { id } = useParams();
  const updateConnectorInstance = async (data: any) => {
    const datasourceServiceManager = new DatasourceService();
    setIsSaving(true);
    setSaveStatus({ ...saveStatus,
      status: '',
      content: ''
    });

    if (datasource) {
      datasource['name'] = data.connectorInstanceName || datasource.name;
      datasource['providerName'] = data.providerName || datasource.providerName;
      datasource['description'] = data.description || datasource.description;
      datasource['documentationUrl'] = data.documentationUrl || datasource.documentationUrl;
      datasource['supportEmail'] = data.supportEmail || datasource.supportEmail;
      datasource['configs'] = data.configs || datasource.configs;
      datasource['imageUrl'] = data.imageUrl || datasource.imageUrl;

      const serializedPlugin = serializePluginAttributes(plugin, definedConfigSchema, dynamicConfigTableData);

      datasource['draftPlugin'] = serializedPlugin[0];

      try {
        await datasourceServiceManager.updateDatasource(datasource);
        setIsSaving(false);
        setSaveStatus({ ...saveStatus,
          status: 'Success',
          content: 'The datasarouce has been successfully updated.'
        });
      } catch (e) {
        console.log(e);
        setIsSaving(false);
        setSaveStatus({ ...saveStatus,
          status: 'Error',
          content: 'There was an error updating the datasource.'
        });
      }
    }
  };

  useEffect(() => {
    const datasourceServiceManager = new DatasourceService();
    const connectorServiceManager = new ConnectorService();

    async function fetchDatasource(id: string) {
      try {
        const params = { id };
        const datasourceResponse = await datasourceServiceManager.getDatasources(params);

        if (datasourceResponse?.data?.data && Array.isArray(datasourceResponse.data.data)) {
          const connectorInstanceItem = datasourceResponse.data.data[0];
          setNumOfSelectedProjects(connectorInstanceItem.tenantViewScope.length);;
          try {
            const params = {
              type: 'available',
              id: connectorInstanceItem.connectorId,
            };
            const connectorResponseObject = await connectorServiceManager.getConnector(params);
            const connector = connectorResponseObject?.data?.data[0];

            setConfigValues({
              identityTagValue: connectorInstanceItem.configs.identityTag,
              definedConfigValues: connectorInstanceItem.configs.definedConfigs,
              dynamicConfigValues: connectorInstanceItem.configs.dynamicConfigs,
            });

            setConfigTemplate({
              ...configTemplate,
                identityTag: connectorInstanceItem.configs.identityTag || '',
                definedConfigs: connector.configTemplate.definedConfigs,
                dynamicConfigs:  connector.configTemplate.dynamicConfigs,
            });

          } catch (e) {
            console.log(e);
          }

          if (connectorInstanceItem.status === 'enabled') {
            setDatasourceStatus('enabled');
          } else {
            setDatasourceStatus('created');
          }

          setBasicInfo({
            connectorInstanceName: connectorInstanceItem.name,
            connectorId: connectorInstanceItem.connectorId,
            providerName: connectorInstanceItem.providerName,
            description: connectorInstanceItem.description,
            documentationUrl: connectorInstanceItem.documentationUrl,
            supportEmail: connectorInstanceItem.supportEmail,
            imageUrl: connectorInstanceItem.imageUrl,
          });

          const configs = connectorInstanceItem.configs;
          const identityTagValue = configs.identityTag;
          const draftPlugin = connectorInstanceItem.draftPlugin;
          const category = connectorInstanceItem.draftPlugin.category || '';

          for (const key in draftPlugin.values) {
            if (draftPlugin.values[key] && draftPlugin.values[key]['status']) {
              draftPlugin.values[key].status = false;
            }
          }

          setPlugin([draftPlugin]);
          setFormData({
            ...formData,
            identityTag: identityTagValue,
            category,
          });

          setAuth({ ...authConfigs, authStatus: connectorInstanceItem.auth.status });
          setDatasource(connectorInstanceItem);
        }
      } catch (e) {
        // TODO: Handle error properly
        console.log(e);
      }
    }
    fetchDatasource(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const datasourceServiceManager = new DatasourceService();
    async function checkConnectorInstanceName(name: string) {
      try {
        const response = await datasourceServiceManager.checkConnectorInstanceName(name);
        setConnectorInstanceNameExists(response?.data.exists);
      } catch (e) {
        // TODO: Handle error properly
        console.log(e);
      }
    }

    if (isUserInput) {
      checkConnectorInstanceName(basicInfo.connectorInstanceName);
    }
  }, [basicInfo.connectorInstanceName, isUserInput]);

  useEffect(() => {
    type Params = { limit: number; }
    const params: Params = { limit };
    const projectServiceManager = new ProjectService();

    async function fetchProjects(params: Params) {
      try {
        const response = await projectServiceManager.getProjects(params);
        const projects = response?.data?.data?.map((item: ProjectResponseBody) => {
          return { key: item.id, name: item.Name };
        });

        setProjects(projects);
      } catch (e) {
        // TODO: Handle error properly
        console.log(e);
      }
    }

    fetchProjects(params);
  }, [limit]);

  const handleTabChange = (e: React.MouseEvent<HTMLElement>, currentTab: string) => {
    e.preventDefault();
    setTab(currentTab);
    setActive(currentTab);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleShare = () => {
    setIsErrorModalActivated(true);
    if (validateOnShare()) {
      setOpenModal(true);
    } else {
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (configTemplate.definedConfigs.length) {
      setDefinedConfigSchema(transformDefinedConfigFields([...configTemplate.definedConfigs]));
    } else {
      setDefinedConfigSchema([]);
    }
  }, [configTemplate.definedConfigs]);

  useEffect(() => {
    setDynamicConfigSchema(transformDynamicConfigFields([...configTemplate.dynamicConfigs]));
  }, [configTemplate.dynamicConfigs]);


  const setAttributeOnPlugin = (values: any, plugin: ContextPlugin[], name: string) => {
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        plugin[0].values[key] = values[name];
      }
    }
  };

  useEffect(() => {
    let idx = Number(ctxIdx) || 0;

    if (dynamicConfigTableData[idx]) {
      dynamicConfigTableData[idx].ctxAttributeName = '';
      dynamicConfigTableData[idx].ctxAttributeCard = null;
      dynamicConfigTableData[idx].outputFieldName = dynamicConfigTableData[idx].defineBtnElement;

      setDynamicConfigTableData([...dynamicConfigTableData]);
    }
    // eslint-disable-next-line
  }, [ctxIdx]);

  const removeCtxAttribute = (name: string, selected: string, tableRowIdx: number, tableCellIdx: number) => {
    setIdxOfCtxAttr('');

    if (selected === 'defined') {
      const updatedConfigFields = definedConfigSchema[tableRowIdx].fields.map((field: any) => {
        if (field.ctxAttributeName === name) {
          field.ctxAttributeName = '';
          field.ctxAttributeCard  = null;
        }

        return field;
      });

      definedConfigSchema[tableRowIdx].fields = updatedConfigFields;
      setDefinedConfigSchema([...definedConfigSchema]);
    }

    if (selected === 'dynamic') {
      setIdxOfCtxAttr((tableRowIdx).toString());
    }

    delete plugin[0].values[name];
    setPlugin([...plugin]);
  };

  const toggleSidePanel = () => {
    setOpenSidePanel((openSidepanel) => !openSidepanel);
  };

  const BasicInfoProps = {
    basicInfo,
    id: id,
    connectorInstanceNameExists,
    setBasicInfo,
    setUserInput,
    updateConnectorInstance,
    datasourceStatus,
    errorsTracker,
    isSaving,
  };

  useEffect(() => {
    if (datasource) {
      datasource.name = basicInfo.connectorInstanceName;
      datasource.providerName = basicInfo.providerName;
      setDatasource(datasource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo.connectorInstanceName, basicInfo.providerName]);

  const validateConfigs = (data: any) => {
    setTrackedConfigs(data);
  };

  const validateOnShare = ():boolean => {
    let errorCount = 0;
    let errorsObj = JSON.parse(JSON.stringify(errorTrackerDefaultValue));
    setErrorsTracker({
      ...errorsTracker,
      basicInfo: errorsObj.basicInfo,
      configurations: errorsObj.configurations
    });

    if (!datasource.name.length) {
      errorCount++;
      errorsObj.basicInfo.name++;
      errorsObj.basicInfo.count++;
    }

    if (!datasource.providerName.length) {
      errorCount++;
      errorsObj.basicInfo.providerName++;
      errorsObj.basicInfo.count++;
    }

    if (trackedConfigs) {
      const configs = trackedConfigs.configs;
      const definedConfigs = configs.definedConfigs;
      const dynamicConfigs = configs.dynamicConfigs;
      const heading = dynamicConfigs.heading;

      if (!configs.identityTag.length) {
        errorCount++;
        errorsObj.configurations.identityTag++;
        errorsObj.configurations.count++;
      }

      if (!configs.category.length) {
        errorCount++;
        errorsObj.configurations.category++;
        errorsObj.configurations.count++;
      }

      if (definedConfigs) {
        for (const key in definedConfigs) {
          const config = definedConfigs[key];
          if (!config.isOutput) {
            const mappings = config.mappings;

            for (const name in mappings) {
              if (mappings[name] && !mappings[name].configVal) {
                errorCount++;
                errorsObj.configurations.definedConfigs++;
                errorsObj.configurations.count++;
              }
            }
          }
        }
      }

      if (heading.dynamicFields.length) {
        for (const field of heading.dynamicFields) {
          for (const key in field) {
            if (!field[key].length) {
              errorCount++;
              errorsObj.configurations.dynamicConfigs++;
              errorsObj.configurations.count++;
            }
          }
        }
      }
    } else {
      const configs = datasource.configs;
      if (!configs.identityTag.length) {
        errorCount++;
        errorsObj.configurations.identityTag++;
        errorsObj.configurations.count++;
      }
    }

    setErrorsTracker({...errorsObj});

    return errorCount > 0 ? false: true ;
  };

  useEffect(() => {
    if (isErrorModalActivated) {
      validateOnShare();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo.providerName,
      basicInfo.connectorInstanceName,
      formData.category,
      formData.identityTag,
      isErrorModalActivated
  ]);

  const ConfigurationProps = {
    basicInfo,
    connectorInstanceId: id,
    selectedConfigData,
    configValues,
    dynamicViewName,
    setSelectedConfigData,
    definedConfigSchema,
    dynamicConfigSchema,
    definedOutputsTableSchema,
    dynamicConfigTableHeaders,
    dynamicConfigTableData,
    selectedConfigType,
    plugin,
    isSaving,
    validateConfigs,
    formData,
    setPlugin,
    setSelectedConfigType,
    setDefinedConfigSchema,
    setDynamicConfigSchema,
    setDynamicConfigTableData,
    setFormData,
    // transformDynamicConfigSchemaToTable,
    updateConnectorInstance,
    toggleSidePanel,
    openSidepanel,
    setDynamicViewName,
    setConfigValues,
    setDefinedOutputTableSchema,
    datasourceStatus,
    setDynamicConfigTableHeaders,
    errorsTracker,
    removeCtxAttribute,
    setAttributeOnPlugin,
  }

  const AuthProps = {
    authConfigs,
    connectorId: basicInfo.connectorId,
    id,
    setAuth,
  }

  const MAIN_CLASS = 'DatasourcesCreation';
  const BODY_CLASS = `${MAIN_CLASS}__body`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  return (
    <div className={MAIN_CLASS}>
      <div className={`${MAIN_CLASS}__header`}>
        <DatasourceHeader
          connectorInstanceName={basicInfo.connectorInstanceName}
          handleShare={handleShare}
          numOfSelectedProjects={numOfSelectedProjects}
          selectedProjects={selectedProjects}
        />

        <FlightSnackbar
          isVisible={saveStatus.status.length > 0}
          isFloating={true}
          animation={true}
          icon={saveStatus.status.toLowerCase()}
          title={saveStatus.status}
          content={saveStatus.content}
          type={saveStatus.status.toLowerCase()}
          isAutoDismiss={true}
          dismissAfter={5000}
        />

        <FlightModal
          isVisible={isShowErrorModal}
          toggleModalShown={() => { setShowErrorModal(false); }}
          scrollable={false}
          size="small"
          warning={true}
          className="custom-modal-class"
          header={<span>Complete Required Fields</span>}
          content={<span>The marked fields are required and must be filled in before sharing your datasource with projects.</span>}
          footer={<div className="modal-footer">
            <FlightButton theme="primary" label="Got it!" onClick={() => { setShowErrorModal(false); }}/>
            </div>}
        />

        {openModal ? (
          <ShareSettings
            openModal={openModal}
            datasource={datasource}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            toggleModal={toggleModal}
          />
        ) : null}
      </div>
      <div className={`${MAIN_CLASS}__body`}>
        <div className={BODY_CLASS}>
          <DatasourceSidebar
            currentTab={currentTab}
            handleTabChange={handleTabChange}
            errorsTracker={errorsTracker}
          />
          <div className={CONTENT_CLASS}>
            {((currentTab) => {
              switch (currentTab) {
                case 'datasource_info':
                  return <DatasourceInfo { ...BasicInfoProps } />;
                case 'configurations':
                  return <Configurations { ...ConfigurationProps } />;
                case 'authorization':
                  return <Authorization { ...AuthProps } />;
                default:
                  return;
              }
            })(currentTab)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatasourcesShare;