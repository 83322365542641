import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import '../../styles/pages/Datasources/manage.scss';
import { useHistory } from 'react-router-dom';
import DatasourceService from '../../services/datasources.service';
import DatasourcesList from '../../components/DatasourcesList/DatasourcesList';
import { Datasource, DatasourceResponseBody } from '../../model/datasources/datasources';
import SearchBar from '../../components/SearchBar/SearchBar';
import { getIcon } from '@flybits/webapp-design-system-react';

const DatasourcesManagementPage: FunctionComponent<{}> = () => {
  const MAIN_CLASS = 'datasources';
  const HEADER_CLASS = `${MAIN_CLASS}__header`;
  const TITLE_CLASS = `${HEADER_CLASS}__title`;
  const HEADER_ACTIONS_CLASS = `${HEADER_CLASS}__actions`;
  const EMPTY_CLASS = `${MAIN_CLASS}__empty`;
  const ICON_CLASS = {
    className: `${MAIN_CLASS}__icon`,
  };

  const [response, setResponse] = useState<Datasource[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const fetchDatasources = async (value?: string) => {
    const datasourceServiceManager = new DatasourceService();
    setLoading(true);
    try {
      const params = {
        limit: 100,
        sort: 'updatedAt-',
        search: value ? value : '',
      };
      const datasourceResponseObject = await datasourceServiceManager.getDatasources(params);
      const transformedResponse: Datasource[] = [];
      datasourceResponseObject.data.data.map((datasource: DatasourceResponseBody) => {
        return transformedResponse.push({
          id: datasource.id,
          connectorId: datasource.connectorId,
          name: datasource.name,
          providerName: datasource.providerName,
          description: datasource.description,
          imageUrl: datasource.imageUrl,
          documentationUrl: datasource.documentationUrl,
          supportEmail: datasource.supportEmail,
          updatedAt: datasource.updatedAt,
          tenantViewScope: datasource.tenantViewScope,
          status: datasource.status,
          auth: datasource.auth,
          configs: datasource.configs,
          draftPlugin: datasource.draftPlugin,
        });
      });
      setLoading(false);
      setResponse(transformedResponse);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleRedirectToDatasourceCreationPage = () => {
    const location = {
      pathname: `/datasources/create`,
    };
    history.push(location);
  };

  useEffect(() => {
    fetchDatasources();
  }, []);

  return (
    <>
      <div className={MAIN_CLASS}>
        <div className={HEADER_CLASS}>
          <div className={TITLE_CLASS}>
            <h3>My Datasources</h3>
          </div>
          <div className={HEADER_ACTIONS_CLASS}>
            <SearchBar searchText={(e) => fetchDatasources(e)} />
            <FlightButton label="+ Create Datasource" size="medium" onClick={handleRedirectToDatasourceCreationPage} />
          </div>
        </div>
        { isLoading ? <div className={`${MAIN_CLASS}__loading`}>{getIcon('loading', ICON_CLASS)}</div> : <></>}
        {  (response.length > 0 && !isLoading) && <DatasourcesList list={response} /> }
        {  (response.length < 1 && !isLoading) && <div className={EMPTY_CLASS}>No datasources have been created.</div> }
      </div>
    </>
  );
};

export default DatasourcesManagementPage;
